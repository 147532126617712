import {
    ToggleDisableUsersById,
    GetDiasbleCheck
} from "../api";
import { FormatMultipleItemIds, GetTableDisableMessage } from "@/utils/table";
import { ShowApiError } from "@/request/error";
export default {
    data() {
        return {
            multipleDisableLoading: false,
            multipleUndisableLoading: false,
            multipleDisableLoadingText: "",
        }
    },
    methods: {
        ToggleDisableRow(row, flag = false) {
            return new Promise((resolve, reject) => {
                let title = flag ? '禁用提示' : '启用提示'
                let massage = flag ? '是否确定要禁用该用户' : '是否确定要启用该用户'
                row.canDisableChecking = true;
                GetDiasbleCheck([row.id]).then(res => {
                    if (res.data) {
                        row.canDisableChecking = false;
                        this.$msgbox({
                            title: title,
                            message: massage,
                            showCancelButton: true,
                            confirmButtonText: "确定",
                            cancelButtonText: "取消",
                            beforeClose: (action, instance, done) => {
                                if (action === "confirm") {
                                    instance.confirmButtonLoading = true;
                                    instance.confirmButtonText = "执行中...";
                                    ToggleDisableUsersById([row.id], flag)
                                        .then((res) => {
                                            this.$message.success("操作成功!");
                                            resolve(res);
                                        })
                                        .catch((err) => {
                                            ShowApiError("操作请求错误", err);
                                            reject(err);
                                        }).finally(() => {
                                            done();
                                            instance.confirmButtonLoading = false;
                                            instance.confirmButtonText = "";
                                        })
                                } else {
                                    instance.confirmButtonLoading = false;
                                    instance.confirmButtonText = "";
                                    done();
                                }
                            },
                        });
                    } else {
                        row.canDisableChecking = false;
                        this.$message.warning("没有权限执行此操作!");
                        reject();
                    }
                }).catch(() => {
                    row.canDeleteChecking = false;
                    this.$message.warning("权限校验异常，请稍后再试");
                    reject();
                });

            })

        },
        onCheckItemDisable() {
            return new Promise((resolve) => {
                this.multipleSelected.forEach((item) => {
                    item.canDisableChecking = true;
                    GetDiasbleCheck([item.id])
                        .then((res) => {
                            if (res.data) {
                                item.canDisable = res.data;
                            }
                        })
                        .finally(() => {
                            item.canDisableChecking = false;
                        });
                });
                let timer = null;
                timer = setInterval(() => {
                    let flag = this.multipleSelected.every(
                        (item) => item.canDisableChecking === false
                    );
                    if (flag) {
                        clearInterval(timer);
                        resolve();
                    }
                }, 1000);
            });
        },
        // 批量：启用/禁用
        onMultipleToggleDisable(flag = false) {
            if (this.multipleSelected.length <= 0) {
                return false;
            }
            this.multipleDisableLoading = flag;
            this.multipleUndisableLoading = !flag;
            this.multipleDisableLoadingText = "正在校验操作许可...";
            this.onCheckItemDisable().then(() => {
                let { ids, nameString, unableControl } = FormatMultipleItemIds({
                    multipleSelectes: this.multipleSelected,
                    nameKey: "name",
                });
                let message = GetTableDisableMessage({
                    total: this.multipleSelected.length,
                    ableControlCount: ids.length,
                    unableControlCount: unableControl.length,
                    nameString: nameString,
                    flag: flag,
                    ableText: "启用",
                    unAbleText: "禁用",
                });
                this.multipleDisableLoadingText = "检验完成，请确认...";
                this.$msgbox({
                    title: `${flag ? "禁用" : "启用"}提示`,
                    message: message,
                    showCancelButton: true,
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    beforeClose: (action, instance, done) => {
                        if (action === "confirm") {
                            if (ids.length <= 0) {
                                this.$message.info("无有效数据可操作");
                                done();
                                this.multipleDisableLoading = false;
                                this.multipleUndisableLoading = false;
                                this.multipleDisableLoadingText = "";
                                instance.confirmButtonLoading = false;
                                instance.confirmButtonText = "";
                                return;
                            }
                            instance.confirmButtonLoading = true;
                            instance.confirmButtonText = "执行中...";
                            ToggleDisableUsersById(ids, flag)
                                .then(() => {
                                    this.$message.success("操作成功");
                                    this.multipleSelected = [];
                                    this.onRefresh();
                                })
                                .catch((err) => {
                                    ShowApiError("操作请求错误", err);
                                }).finally(() => {
                                    done();
                                    instance.confirmButtonLoading = false;
                                    instance.confirmButtonText = "";
                                    this.multipleDisableLoading = false;
                                    this.multipleUndisableLoading = false;
                                    this.multipleDisableLoadingText = "";
                                });
                        } else {
                            this.multipleDisableLoading = false;
                            this.multipleUndisableLoading = false;
                            this.multipleDisableLoadingText = "";
                            instance.confirmButtonLoading = false;
                            instance.confirmButtonText = "";
                            done();
                        }
                    },
                });
            })
        },
    }
}