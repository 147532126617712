import {
    GetUsersRoles,
} from "../api";
import { ShowApiError } from "@/request/error";
export default {
    data() {
        return {
            rolesOptions: [],
            userOptions: []
        }
    },
    methods: {
        initFormOptions() {



            GetUsersRoles()
                .then((res) => {
                    this.rolesOptions = res.data;
                })
                .catch((err) => {
                    ShowApiError("获取用户角色选单失败", err);
                });
        },
    }
}